import React, { useState, useRef, useEffect, useCallback } from 'react';
import YouTube, { YouTubeEvent } from 'react-youtube';

interface YoutubeBGMPlayerProps {
  youtubeId: string;
}

const YoutubeBGMPlayer: React.FC<YoutubeBGMPlayerProps> = ({ youtubeId }) => {
  const [muted, setMuted] = useState(true);
  const playerRef = useRef<any>(null);

  const onReady = (event: YouTubeEvent) => {
    playerRef.current = event.target;
    playerRef.current.playVideo();

    setTimeout(() => {
      try {
        playerRef.current.setVolume(50);
      } catch (error) {
        console.error(error);
      }
    }, 500);

    updateMuteState();
  };

  const updateMuteState = useCallback(() => {
    if (playerRef.current == null) return;
    try {
      if (muted) playerRef.current.mute();
      else playerRef.current.unMute();
    } catch (err) {
      console.error(err);
    }
  }, [muted]);

  useEffect(() => {
    updateMuteState();
  }, [muted, updateMuteState]);

  const toggleMute = () => {
    setMuted((prev) => !prev); // 상태 토글
  };

  const opts = {
    height: '0',
    width: '0',
    playerVars: {
      autoplay: 1,
      controls: 0,
      loop: 1,
      start: 3,
    },
  };

  return (
    <div>
      <YouTube videoId={youtubeId} opts={opts} onReady={onReady} />
      <button onClick={toggleMute}
        // style={{ backgroundColor: 'transparent', border: '0', color: 'white', fontSize: "2vh" }}>
        className="btn rounded-pill"
        style={muted
          ? { backgroundColor: '#555555', color: '#ffffff', width: '50px' }
          : { backgroundColor: '#222222', color: '#ffffff', width: '50px' }
        }
      >
        {muted ?
          <i className="bi bi-volume-mute-fill"></i> :
          <i className="bi bi-volume-up-fill"></i>}
      </button>
    </div >
  );
};

export default YoutubeBGMPlayer;
