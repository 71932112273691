import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import logo from './assets/logo-circle-white-outline.png';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import YoutubeBGMPlayer from './utils/youtube-bgm-player';
import { getCurrentLangauge, Language } from './utils/language-manager';

// Bootstrap Icons: https://icons.getbootstrap.com/

const App: React.FC = () => {
  const [language, setLanguage] = useState<Language>(getCurrentLangauge);
  const [introText, setIntroText] = useState('');

  const introMessages = useMemo(() => ({
    en: 'Hello! I am HisaCat.\nI am a game developer who mainly spends time on VRChat.\nFeel free to reach out on Twitter or Discord!',
    ko: '안녕하세요! 저는 HisaCat입니다.\n저는 게임 개발자이며 주로 VRChat에서 시간을 보냅니다.\n언제든지 트위터나 디스코드로 연락하세요!',
    ja: 'こんにちは！HisaCatです。\n私はゲーム開発者で、主にVRChatで活動しています。\n気軽にTwitterやDiscordで連絡してください！',
  }), []);

  useEffect(() => {
    document.documentElement.lang = language;
    setIntroText(introMessages[language]);
  }, [language, introMessages]);

  const handleLanguageChange = (lang: Language) => {
    setLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  return (
    <Container fluid className="w-100 p-4">
      {/* Background gradient */}
      <div className="background-image"></div>
      <div className="background-overlay"></div>
      <Container fluid className=" w-100 p-4 text-center d-flex flex-column align-items-center justify-content-center content-container">
        <h1 className="hisa-title display-4 display-md-3">
          {'HisaCat'.split('').map((char, index) => (
            <span
              key={index}
              className="bounce"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              {char}
            </span>
          ))}
        </h1>
        <img
          src={logo}
          className="logo mb-4 img-fluid"
          alt="HisaCat Logo"
          style={{ maxWidth: '200px', height: 'auto' }}
        />

        {/* BGM */}
        <YoutubeBGMPlayer youtubeId="4P18PKq2qgc" />

        <p className="intro-text">
          {introText.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}<br />
            </React.Fragment>
          ))}
        </p>

        {/* 언어 선택 버튼 */}
        <div className="language-buttons mb-4">
          <button onClick={() => handleLanguageChange('en')} className="lang-button">English</button>
          <button onClick={() => handleLanguageChange('ko')} className="lang-button">한국어</button>
          <button onClick={() => handleLanguageChange('ja')} className="lang-button">日本語</button>
        </div>

        {/* Button group */}
        <div className="btn-group-vertical w-100">
          <a href="https://github.com/hisacat" className="btn rounded-pill w-100"
            style={{ backgroundColor: '#2b3849', color: '#ffffff' }}
          >
            <i className="bi bi-github me-2"></i> GitHub
          </a>
          <a href="https://www.youtube.com/@ahisacat" className="btn rounded-pill w-100"
            style={{ backgroundColor: '#d80000', color: '#ffffff' }}
          >
            <i className="bi bi-youtube me-2"></i> Youtube
          </a>
          <a href="https://hisacat.booth.pm" className="btn rounded-pill w-100"
            style={{ backgroundColor: '#fc4d50', color: '#ffffff' }}
          >
            <i className="bi bi-bag-fill me-2"></i> Booth
          </a>
          {/* Row for horizontal buttons */}
          <div className="d-flex justify-content-between w-100">
            <a href="https://hisacat.tistory.com" className="btn rounded-pill w-100 mx-1"
              style={{ backgroundColor: '#ca4638', color: '#ffffff' }}
            >
              <i className="bi bi-pencil-square me-2"></i> Blog
            </a>
            <a href="https://twitter.com/ahisacat" className="btn rounded-pill w-100 mx-1"
              style={{ backgroundColor: '#1da1f2', color: '#ffffff' }}
            >
              <i className="bi bi-twitter me-2"></i> Twitter
            </a>
            <a href="https://discord.com/users/295816286213242880" className="btn rounded-pill w-100 mx-1"
              style={{ backgroundColor: '#5865f2', color: '#ffffff' }}
            >
              <i className="bi bi-discord me-2"></i> Discord
            </a>
          </div>
          {/* <a href="https://discord.com/users/295816286213242880" className="btn btn-danger rounded-pill w-100 mx-1">
              Test
            </a> */}
        </div>

        {/* Contact section */}
        <p className="bottom-text mt-4 mb-2">
          <a href="mailto:ahisacat@gmail.com">e-mail: ahisacat@gmail.com</a>
        </p>
        <a href="https://hits.sh/hisa.cat/" className='mb-4'><img alt="Hits" src="https://hits.sh/hisa.cat.svg" /></a>

        {/* Showcase */}
        <Container fluid className="w-100 p-0">
          <hr style={{ border: 'none', height: '5px', backgroundColor: 'white' }} />
          <h1>Showcase</h1>
          <hr style={{ border: 'none', height: '5px', backgroundColor: 'white' }} />
          <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', }}>
            <iframe
              src="https://www.youtube.com/embed/3e4z04yDyxg?si=J61OOBw9k1NJFVbX"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </div>
          <p>
            <a href="https://hisacat.booth.pm/items/5660552" style={{ color: 'white', fontSize: '1.2rem' }}>
              『トラス・Tolass』Facial Tracking
            </a>
          </p>
        </Container>
      </Container>
    </Container>
  );
};

export default App;
