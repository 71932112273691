
type Language = 'en' | 'ko' | 'ja';
const getCurrentLangauge = (): Language => {
    const savedLang = localStorage.getItem('lang');
    const userLang = savedLang || navigator.language || navigator.languages[0];

    if (userLang.startsWith('ko'))
        return 'ko';
    else if (userLang.startsWith('ja'))
        return 'ja';
    else
        return 'en';
};

export type { Language }
export { getCurrentLangauge }
